<template lang="pug">
    v-container.explore.px-0.text-center(fluid)
        v-container
          .custom-container
              .autocomplete-block
                img(src="@/assets/images/search-icon.png")
                gmap-autocomplete(@place_changed="setPlace" :options="autocompleteOptions" placeholder="Search").location-input
              v-btn.custom-btn(to='/search' color="" @click="")
                img(src="@/assets/images/filter-icon.png")
</template>

<script>
  export default {
    components: {
    //   Places,
    },
    data: () => ({
      autocompleteOptions: {
        componentRestrictions: {
          country: [
            'us',
          ],
        },
      },
      loading: false,
      items: [],
      select: null,
    }),
    computed: {
      currentSearch () {
        return this.$store.getters.getCurrentSearch
      },
      profile () {
        return this.$store.state.users.profile
      },
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
    methods: {
      querySelections (v) {
        this.loading = true
        // Simulated ajax query
        setTimeout(() => {
          this.items = this.states.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          })
          this.loading = false
        }, 500)
      },
      setPlace (place) {
        // console.log(place)
        const vm = this
        vm.$store.dispatch('search/setCurrentSearch', place).then(() => {
          this.$router.replace('/search')
        })
      },
    },

  }
</script>

<style lang="scss">
    $primary-color: var(--secondary-color);
    $text-color: #858585;
    .explore{
      .custom-container{
        display: flex;
        align-items: center;
        justify-content: center;
        .autocomplete-block{
            padding: 0 1rem;
            display: grid;
            grid-template-columns: 40px max-content;
            align-items: center;
            background-color: var(--white-color);
            border-radius: 39px;
            box-shadow: -7px 4px 38px rgba(0, 0, 0, 0.16);
            height: 50px;
            img{
                width: 100%;
            }
            .location-input{
                height: 100%;
                border: none;
                padding: 0 1rem;
                font-size: 12pt;
                width: 250px;
                max-width: 100%;
                &:focus{
                    outline: none !important;
                }
            }
        }
        .v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab), .v-btn.v-size--large:not(.v-btn--icon):not(.v-btn--fab){
          padding: 0 !important;
        }
        .custom-btn{
          background-color: transparent !important;
          margin: 0 !important;
          padding: 0 !important;
          border-radius: 0;
          box-shadow: none;
          position: relative;
          .v-btn__content{
            z-index: 5;
          }
          &::after{
            content: "";
            width: 50px;
            height: 50px;
            background-color: #fff;
            // background-color: #808184;
            // box-shadow: -3px 2px 16px 5px rgba(0, 0, 0, 0.07);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0;
            box-shadow: none;
          }
          img{
            width: auto;
            height: 30px;
          }
        }
      }
        .theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
            background-color: $primary-color;
        }
        .section-header{
            color: $text-color;
            font-size: 19.5pt;
            line-height: 19.8pt;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;

            &__title, &__title-2{
                letter-spacing: -0.9pt;
                b{
                    color: #8a8a8a;
                    font-weight: 800;
                }
            }
            &__title-2{
                font-size: 16.3pt;
            }
            &__divider{
                width: 115.3pt;
                border-color: $primary-color !important;
                border-width: 0.5pt;
                margin:auto;
                margin-top: 7pt;
                letter-spacing: -0.81pt;

            }
        }
        .section-description{
            margin-top: 1rem;
            margin-bottom: 1rem;
            text-align: center;
            color: $text-color;
            font-size:13.5pt;
            line-height: 14.5pt;
            letter-spacing: -0.7pt;
            padding-left: 47.5pt;
            padding-right: 47.5pt;

        }
        &__steps{
            .step-content{
                display: flex;
                justify-content: center;
                &__tab-header{
                    border: 1px solid black;
                    border-radius: 25px;
                    padding: 4px 10px;
                    top: 0;
                }
                &__title{
                    line-height: 12px;
                    white-space: pre-line
                }

            }
            .step-icon{
                transition: 0.3s;
                top: 0px;

            }
        }
        .v-item--active{
            .step-content__tab-header{
                background:$primary-color;
                color: #fff;
                border-color: transparent;
            }
            .step-content__title{
                display: none;
            }
            .step-icon{
                &.icon__0, &.icon__2{
                    transform: scale(2);
                    top: 30px;
                }
                &.icon__1{
                    transform: scale(3);
                    top: 30px;
                }

            }
        }
         .v-autocomplete__content{
            top:50px !important;
            left:0 !important;
        }
        .location-input{
          background-color: var(--white-color);
          height: 50px;
          padding: 0 1rem;
          border-radius: 5px;
        }
    }
</style>
