<template lang="pug">
  .home-wrapper.px-0
    .home-wrapper__brand.text-center
      img(:src="brandIcon")
      img.bg-overlay(:src="bannerBg")
    .display-1.text-center() Search and Discover evidence-based content shared anonymously from contributors
    Explore
    HowToUse.white.pt-10.mb-5(v-if="isMobile")
    CategorySearch(v-if="!isMobile").hidden-sm-and-down
    RecentVideos
</template>

<script>
  import bannerBg from '@/assets/images/banner-bg.png'
  import brandIcon from '@/assets/images/brand-icon-dsk.png'
  import Explore from './component/Explore'
  import HowToUse from '@/components/custom/HowToUse'
  import RecentVideos from './component/RecentVideos.vue'
  import CategorySearch from './component/CategorySearch'
  export default {
    components: {
      Explore,
      HowToUse,
      CategorySearch,
      RecentVideos,
    },
    data () {
      return {
        brandIcon: brandIcon,
        bannerBg: bannerBg,
      }
    },

    computed: {
      isMobile () {
        return window.innerWidth < 1024
      },
      drawer () {
        return this.$store.state.drawer.drawer
      },
    },

    watch: {
      scrollY: function (val) {
        const scroll = window.scrollY
        return scroll
      },
    },
    created () {},

    methods: {
      setDrawer (payload) {
        this.$store.dispatch('drawer/set_drawer', payload)
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
