<template lang='pug'>
    v-container(fluid).px-0
        // Confirmation buy dialog
        v-dialog(v-model='confirmationDialog' width="400" persistent transition="dialog-bottom-transition")
          div(style ='position:relative; height: 100%;')
            confirmDialog(v-if="confirmationDialog" :item="currentItem" @close="confirmationDialog=false")
            div(style='position:absolute; top:0; width:100%;')
              v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='confirmationDialog=false')
                v-icon(size="30") mdi-close-box
        v-dialog(:fullscreen='isMobile' persistent :value='watchDialog'  max-width='600px'   transition="dialog-bottom-transition")
          div(style ='position:relative; height: 100%;')
            watch-videos(:model ='itemCard' v-if="watchDialog" @close="watchDialog=false")
            div(style='position:absolute; top:0; width:100%;')
              v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='watchDialog=false')
                v-icon(size="30") mdi-close-box
        v-row
            v-col(cols="12" style=" max-width: 100%; overflow-x: auto;").text-center
                v-btn-toggle.search-category
                    v-btn.search-category__btn(color="#bebebe" dense @click="changeCategory('Accident')")
                      img(src="@/assets/images/car-crash.png")
                      span Accident
                    v-btn.search-category__btn(color="#bebebe" dense @click="changeCategory('Vandalism')")
                        img(src="@/assets/images/vandalism.png")
                        span Vandalism
                    v-btn.search-category__btn(color="#bebebe" dense @click="changeCategory('Home invasion')")
                        img(src="@/assets/images/homeInvasion.png")
                        span Home Invasion
                    v-btn.search-category__btn(color="#bebebe" dense @click="changeCategory('Crimes')")
                        img(src="@/assets/images/crimes.png")
                        span Crimes
                    v-btn.search-category__btn(color="#bebebe" dense @click="changeCategory('Historic events')")
                        img(src="@/assets/images/historicEvents.png")
                        span Historic Events
                    v-btn.search-category__btn(color="#bebebe" dense @click="changeCategory('Humanitarian')")
                        img(src="@/assets/images/humanitarian.png")
                        span Humanitarian
                    v-btn.search-category__btn(color="#bebebe" dense @click="changeCategory('Unusual vacation spots')")
                        img(src="@/assets/images/vacationSpots.png")
                        span Unusual Vacation Spots
            v-col(cols="12")
                gmap-map(:center="center" :zoom="zoom" style="width:100%;  height: 700px;" :options="optionMap")
                    gmap-marker(:icon='markerOptions(m)' v-for="(m, index) in markers" :key="index" :animation="3" :position="m.position" :clickable="true" @click="openInfoWindow(m)")
                    gmap-info-window(:options="infoOptions" :position="infoContent.position" :opened="infoWinOpen" @closeclick="infoWinOpen=false")
                      v-card.video-card.video-card--recent(flat elevation="0")
                        .video-card__img-wrapper( :style="{ backgroundImage: 'url(' + infoContent.data.thumbnail + ')' }")
                        v-layout
                          v-flex.justify-center.video-card__title
                            h2.text-center {{infoContent.data.title}}
                        v-flex.d-flex.align-center.justify-center
                          span.my-1.video-card__location.primary--text {{infoContent.data.type}}
                        v-layout
                          v-flex.d-flex.align-center.justify-center
                            img(src="@/assets/images/location-icon.png")
                            span.ml-2.video-card__location {{infoContent.data.location.direction}}, {{infoContent.data.location.state}}
                        v-layout.video-card__information
                          v-layout.d-flex.align-center.justify-center(col="6")
                            img(src="@/assets/images/calendar-icon.png")
                            p.ml-2.video-card__date {{infoContent.data.date | moment}}
                          v-layout.d-flex.align-center.justify-center(col="6")
                            img(src="@/assets/images/time-icon.png")
                            p.ml-2.video-card__time {{infoContent.data.time}}
                        v-layout
                          v-layout(col="4")
                            v-flex.d-flex.align-center.justify-center
                              //- p.mb-0.video-card__price {{video.price | moneyFormat}}
                        v-layout(v-if="owned(infoContent.data)")
                          v-layout(col="6")
                            v-flex.d-flex.align-center.justify-center
                              v-btn.text-uppercase.mx-auto.video-card__buy( style='max-width: 150px;' color="#ff0000" small @click="showCard(infoContent.data)") Watch
                        v-layout(v-else)
                          v-layout(col="4")
                            v-flex.d-flex.align-center.justify-center
                              p.mb-0.video-card__price {{infoContent.data.price | moneyFormat}}
                          v-layout(col="4")
                            v-flex.d-flex.align-center.justify-center
                              v-btn.text-uppercase.mx-auto.video-card__buy(color="#ff0000" small @click="openConfirmationDialog(infoContent.data)") Buy

                          // v-layout(col="4")
                            v-flex.d-flex.align-center.justify-center
                              v-btn.text-uppercase.mx-auto.video-card__buy(color="#ff0000" small @click="openConfirmationDialog(infoContent.data)") Buy
                          v-layout(col="4")
                            <!--v-flex.d-flex.align-center.justify-center-->
                              //- router-link.video-card__show-more(to="/" tag="a") Show more
                      <!--v-row-->
                        <!--v-col-->
                            <!--img(:src="infoContent.data.thumbnail" width="200")-->
                            <!--div.text-center.mt-3-->
                                <!--v-btn(small color="success" @click="openConfirmationDialog(infoContent.data)" :loading="isLoading") Buy-->
                        <!--v-col-->
                            <!--v-card-title.mt-1 {{infoContent.data.title}}-->
                            <!--v-card-text-->
                                <!--div.mt-1-->
                                <!--| {{infoContent.data.description}}-->
                                <!--.mt-1.subtitle-1-->
                                <!--v-icon(small='') mdi-map-marker-->
                                <!--| {{infoContent.data.location.direction}}, {{infoContent.data.location.locality}}, {{infoContent.data.location.state}}-->
                                <!--.mt-1.subtitle-1-->
                                <!--v-icon(small='') mdi-calendar-->
                                <!--| {{infoContent.data.date}} {{infoContent.data.time}}-->
                                <!--.mt-1.subtitle-1-->
                                <!--span.mr-2(style="color:red")-->
                                    <!--| {{infoContent.data.category}}-->
                                <!--span-->
                                    <!--v-icon(small='').mr-1 mdi-clock-->
                                    <!--| {{infoContent.data.duration}}-->
                                <!--.mt-1.orange&#45;&#45;text-->
                                <!--h4 {{infoContent.data.price | moneyFormat}}-->
</template>

<script>
  import axios from 'axios'
  import loaders from '@/plugins/mixins/loaders'
  import * as vars from '@/vars.json'
  import moment from 'moment'
  // icons
  import accidentIcon from '@/assets/icons/search/accident.png'
  import vandalismIcon from '@/assets/icons/search/vandalism.png'
  import homeInvasion from '@/assets/icons/search/home_invasion.png'
  import crimesIcon from '@/assets/icons/search/crimes.png'
  import humanitarian from '@/assets/icons/search/humanitarian.png'
  import historicEvents from '@/assets/icons/search/historic_events.png'
  import vacationSpots from '@/assets/icons/search/unusual.png'
  import defaultIcon from '@/assets/icons/search/map-marker.png'

  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  })
  export default {

    filters: {
      moneyFormat: function (value) {
        return formatter.format(value)
      },
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
    },

    components: {
      WatchVideos: () => import('@/components/custom/dialogs/Videos/WatchDialog.vue'),
      confirmDialog: () => import('@/components/custom/dialogs/ConfirmPurchase.vue'),
    },

    mixins: [loaders],

    data () {
      return {
        itemCard: '',
        watchDialog: false,
        markerIcon: '',
        defaultIcon: defaultIcon,
        crimesIcon: crimesIcon,
        accidentIcon: accidentIcon,
        vandalismIcon: vandalismIcon,
        homeInvasion: homeInvasion,
        humanitarian: humanitarian,
        vacationSpots: vacationSpots,
        historicEvents: historicEvents,
        zoom: 5,
        center: {
          lat: 39.797166,
          lng: -100.955272,
        },
        category: '',
        markers: [],
        preset: '',
        currentPlace: null,
        infoContent: {
          position: {
            lat: 37.0902405,
            lng: -95.7128906,
          },
          data: {
            location: '',
          },
        },
        infoWinOpen: false,
        infoOptions: {
          pixelOffset: {
            width: -10,
            height: -95,
          },
        },
        snackbar: {
          show: false,
          message: '',
          color: 'red',
        },
        confirmationDialog: false,
        currentItem: null,
        optionMap: {
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: true,
          gestureHandling: 'greedy',
        },
        loginDialog: false,
        registerDialog: false,
      }
    },

    computed: {
      downloads () {
        return this.$store.getters['downloads/getDownloads']
      },
      drawer () {
        return this.$store.state.drawer.drawer
      },
      isLoading () {
        return this.$store.getters['downloads/getLoading']
      },
      place () {
        return this.$store.getters['search/getCurrentSearch'] || null
      },
      user () {
        return this.$store.state.users.user
      },
    },
    watch: {
      scrollY: function (val) {
        const scroll = window.scrollY
        return scroll
      },
    },

    created () {
      const vm = this
      vm.markerIcon = accidentIcon
      vm.geolocate()
      vm.search()
      vm.loadMedia()
    },

    methods: {
      owned (item) {
        // console.log(item)
        const vm = this
        if (vm.downloads) {
          const found = vm.downloads.find(element => element.url === item.url)
          if (found) {
            return true
          }
        }
        return false
      },
      showCard (card) {
        const vm = this
        vm.itemCard = card
        vm.watchDialog = true
        // console.log(vm.watchDialog)
      },
      openDialog (dialog) {
        this.$store.dispatch('dialogs/openDialog', dialog).then(function () {
          // // console.log('dialog set on : ' + dialog)
        })
      },
      markerOptions (data) {
        const vm = this
        // console.log(data)
        let icon
        switch (data.data.category) {
          case 'Accident':
            icon = vm.accidentIcon
            break
          case 'Vandalism':
            icon = vm.vandalismIcon
            break
          case 'Home invasion':
            icon = vm.homeInvasion
            break
          case 'Crimes':
            icon = vm.crimesIcon
            break
          case 'Humanitarian':
            icon = vm.humanitarian
            break
          case 'Historic events':
            icon = vm.historicEvents
            break
          case 'Unusual vacation spots':
            icon = vm.vacationSpots
            break
          default :
            icon = vm.defaultIcon
            break
        }
        return {
          url: icon,
          size: { width: 70, height: 70, f: 'px', b: 'px' },
          scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
        }
      },
      setDrawer (payload) {
        this.$store.dispatch('drawer/set_drawer', payload)
      },
      geolocate: function () {
        const vm = this
        navigator.geolocation.getCurrentPosition(position => {
          if (position) {
            vm.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
          } else {
            vm.center = {
              lat: 39.797166,
              lng: -100.955272,
            }
          }
        })
      },

      setMarkers (results) {
        const vm = this
        vm.markers = []
        if (results) {
          results.forEach(element => {
            const marker = element.location.latlng
            this.markers.push({ position: marker, data: element })
          })
        }
      },

      changeCategory (category) {
        const vm = this
        vm.category = category
        vm.search()
      },

      search () {
        const vm = this
        const requestData = {
          location: vm.currentPlace,
          category: vm.category,
          preset: vm.preset,
          distance: vm.range,
        }
        vm.dialog = false
        // console.log(requestData)
        axios.post(vars.urlProd + '/searchMedia', requestData).then(res => {
          // console.log(res)
          vm.setMarkers(res.data)
        }).catch(e => {
          // console.log(e)
        })
      },

      openInfoWindow (marker) {
        // console.log('aqui', marker)
        const vm = this
        vm.center = marker.position
        vm.zoom = 15
        vm.infoContent = marker
        vm.infoWinOpen = true
      },

      openConfirmationDialog (download) {
        const vm = this
        if (!vm.user) {
          vm.openDialog('login')
        } else {
          vm.currentItem = download
          vm.confirmationDialog = true
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  $primary-color: var(--secondary-color);
  $text-color: var(--grey-color);
  .video-card{
    min-width: 300px !important;
    min-height: 300px !important;
    margin: 0 auto;
    &__title,
    &__description,
    &__location,
    &__time,
    &__date{
      text-transform: uppercase;
      color: #a3a3a3;
    }
    &__buy{
      font-size: 18px !important;
    }
    &__show-more{
      color: var(--secondary-color) !important;
      font-size: 15px !important;
    }
    &__price{
      font-weight: bold;
      font-size: 20px;
      color: var(--secondary-color);
    }
  }
  .videos{
    .section-header{
      color: $text-color;
      font-size: 19.5pt;
      line-height: 19.8pt;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      &__title, &__title-2{
        letter-spacing: -0.9pt;
        b{
          color: #8a8a8a;
          font-weight: 800;
        }
      }
      &__title-2{
        font-size: 16.3pt;
      }
      &__divider{
        width: 115.3pt;
        border-color: $primary-color !important;
        border-width: 0.5pt;
        margin:auto;
        margin-top: 7pt;
        letter-spacing: -0.81pt;
      }
    }
  }
  #map{
    height: 600px;
  }
  .location-input{
    width: 100%;
    border-bottom: 1px solid #d2d2d2;
    width: 10rem;
  }
  .v-card--reveal {
      align-items: center;
      bottom: 0;
      justify-content: center;
      opacity: .8;
      position: absolute;
      width: 100%;
  }
</style>
