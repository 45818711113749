<template lang="pug">
    v-container.videos.px-0.text-center(fluid)
        //- Confirmation buy dialog
        v-dialog(v-model='confirmationDialog' width="400" persistent transition="dialog-bottom-transition")
          div(style ='position:relative; height: 100%;')
            confirmDialog(v-if="confirmationDialog" :item="currentItem" @close="confirmationDialog=false")
            div(style='position:absolute; top:0; width:100%;')
              v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='confirmationDialog=false')
                v-icon(size="30") mdi-close-box
        v-dialog(:fullscreen='isMobile' persistent :value='watchDialog'  max-width='600px'   transition="dialog-bottom-transition")
          div(style ='position:relative; height: 100%;')
            watch-videos(:model ='itemCard' v-if="watchDialog" @close="watchDialog=false")
            div(style='position:absolute; top:0; width:100%;')
              v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='watchDialog=false')
                v-icon(size="30") mdi-close-box
        // Content
        v-layout.section-header
            .section-header__title.text-uppercase.mt-10
                |Recent &nbsp;
                b Content
            v-divider.section-header__divider.mb-5(dark)
        carousel.mb-10.mx-auto(style='max-width: 1280px' :paginationEnabled="!isMobile" :perPage="isMobile ? 1 : 4"  :minSwipeDistance="1")
          slide.slide(v-for='(item, i) in media')
            v-card.video-card.video-card--recent(to="/")
              .video-card__img-wrapper( :style="{ backgroundImage: 'url(' + item.thumbnail + ')' }")
              v-layout
                v-flex.justify-center.video-card__title
                  h2 {{item.title}}
              v-flex.d-flex.align-center.justify-center
                span.my-1.video-card__location.primary--text {{item.type}}
              v-layout
                v-flex.d-flex.align-center.justify-center
                  img(src="@/assets/images/location-icon.png")
                  span.ml-2.video-card__location {{item.location.direction}}, {{item.location.state}}
              v-layout.video-card__information
                v-layout.d-flex.align-center.justify-center(col="6")
                  img(src="@/assets/images/calendar-icon.png")
                  p.ml-2.video-card__date {{item.date | moment}}
                v-layout.d-flex.align-center.justify-center(col="6")
                  img(src="@/assets/images/time-icon.png")
                  p.ml-2.video-card__time {{item.time}}
              v-layout(v-if="owned(item)")
                v-layout(col="6")
                  v-flex.d-flex.align-center.justify-center
                    v-btn.text-uppercase.mx-auto.video-card__buy( style='max-width: 150px;' color="#ff0000" small @click="showCard(item)") Watch
              v-layout(v-else)
                v-layout(col="4")
                  v-flex.d-flex.align-center.justify-center
                    p.mb-0.video-card__price {{item.price | moneyFormat}}
                v-layout(col="4")
                  v-flex.d-flex.align-center.justify-center
                    v-btn.text-uppercase.mx-auto.video-card__buy(color="#ff0000" small @click="openConfirmationDialog(item)") Buy
                v-layout(col="4")
                  v-flex.d-flex.align-center.justify-center
                    a.mx-auto( style='font-size: 12px;' outlined color="secondary" small @click="showCard(item)") Show more
</template>

<script>

  import propertyImage from '@/assets/images/icon-faq.png'
  import mapImage from '@/assets/images/icon-terms.png'
  import loaders from '@/plugins/mixins/loaders'
  import { Carousel, Slide } from 'vue-carousel'
  import moment from 'moment'

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  export default {
    components: {
      Carousel,
      Slide,
      WatchVideos: () => import('@/components/custom/dialogs/Videos/WatchDialog.vue'),
      confirmDialog: () => import('@/components/custom/dialogs/ConfirmPurchase.vue'),
    },

    filters: {
      moneyFormat: function (value) {
        return formatter.format(value)
      },
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
    },

    mixins: [loaders],
    data: () => ({
      itemCard: '',
      watchDialog: false,
      propertyImage: propertyImage,
      mapImage: mapImage,
      reviews: 413,
      value: 4.5,
      loading: false,
      items: [],
      search: null,
      select: null,
      showSlider: false,
      options: {
        selector: '.siema',
        duration: 200,
        easing: 'ease-out',
        perPage: 1,
        startIndex: 1,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: false,
        rtl: false,
      },
      confirmationDialog: false,
      currentItem: null,
    }),
    computed: {
      downloads () {
        return this.$store.getters['downloads/getDownloads']
      },
      isMobile () {
        return window.innerWidth < 1024
      },
      showHeader () {
        return this.$route.path.indexOf('/property/')
      },
      propertyList () {
        return this.$store.getters.getProperties
      },
      media () {
        return this.$store.getters['uploads/getMedia']
      },
      user () {
        return this.$store.state.users.user
      },
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
      propertyList (val) {
        const vm = this
        if (val.length > 0) {
          setTimeout(function () {
            vm.showSlider = true
          }, 1000)
        }
      },
    },
    created () {
      const vm = this
      vm.loadMedia()
    },
    methods: {
      owned (item) {
        const vm = this
        if (vm.downloads) {
          const found = vm.downloads.find(element => element.url === item.url)
          if (found) {
            return true
          }
        }
        return false
      },
      showCard (card) {
        const vm = this
        vm.itemCard = card
        vm.watchDialog = true
        console.log(vm.watchDialog)
      },
      switchDialog (to) {
        const vm = this
        switch (to) {
          case 'login':
            vm.registerDialog = false
            vm.loginDialog = true
            break
          case 'register':
            vm.loginDialog = false
            vm.registerDialog = true
            break
        }
      },
      openLoginDialog () {
        this.loginDialog = true
      },
      openRegisterDialog () {
        this.registerDialog = true
      },
      querySelections (v) {
        this.loading = true
        // Simulated ajax query
        setTimeout(() => {
          this.items = this.states.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          })
          this.loading = false
        }, 500)
      },
      openDialog (dialog) {
        this.$store.dispatch('dialogs/openDialog', dialog).then(function () {
          // console.log('dialog set on : ' + dialog)
        })
      },
      openConfirmationDialog (download) {
        const vm = this
        if (!vm.user) {
          vm.openDialog('login')
        } else {
          vm.currentItem = download
          vm.confirmationDialog = true
        }
      },
    },

  }
</script>

<style lang="scss">
    $primary-color: var(--secondary-color);
    $text-color: var(--grey-color);
    .video-card{
      width: 300px;
      margin: 0 auto;
      &__title,
      &__description,
      &__location,
      &__time,
      &__date{
        text-transform: uppercase;
        color: #a3a3a3;
      }
      &__buy{
        font-size: 18px !important;
      }
      &__show-more{
        color: var(--secondary-color) !important;
        font-size: 15px !important;
      }
      &__price{
        font-weight: bold;
        font-size: 20px;
        color: var(--secondary-color);
      }
    }
    .videos{
        .section-header{
            color: $text-color;
            font-size: 19.5pt;
            line-height: 19.8pt;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;

            &__title, &__title-2{
                letter-spacing: -0.9pt;
                b{
                    color: #8a8a8a;
                    font-weight: 800;
                }
            }
            &__title-2{
                font-size: 16.3pt;
            }
            &__divider{
                width: 115.3pt;
                border-color: $primary-color !important;
                border-width: 0.5pt;
                margin:auto;
                margin-top: 7pt;
                letter-spacing: -0.81pt;
            }
        }
    }
</style>
