<template lang="pug">
    v-container.how-to-use.px-0(fluid)
      v-card-title(v-if="modal")
        v-spacer
        v-icon(aria-label="Close" large @click="$emit('close')")
          | mdi-close
      v-layout.section-header
          .section-header__title.text-uppercase
              |How to &nbsp;
              span.custom-accent use &nbsp;
              b Scene Finder as a customer?
              v-divider.section-header__divider(dark)
      v-item-group(style="z-index:1;").how-to-use__steps.mb-4
          v-container(grid-list-md='')
              v-layout(wrap='')
                  v-flex(v-for='item, index in steps', :key='index', xs4='')
                      v-item
                          v-card.d-flex.align-center.custom-card(:ripple="false" flat slot-scope='{ active, toggle }',   @click='toggle')
                              v-layout(column justify-center align-center @click='setContent(index)').step-content.text-xs-center
                                  .step-content__tab-header.mb-2 {{item.tabtitle}}
                                  .custom-step
                                    img.custom-step__icon(:src="item.icon")
                                  //- v-img.step-icon(:class="'icon__'+index" contain :width='item.size.width' :height='item.size.height' :src='item.icon')
                                  h4.step-content__title.text-uppercase {{item.title}}
      transition(name='fade', absolute enter-active-class='fadeInUp', leave-active-class='fadeOutUp')
          v-layout(:key="selectedStep.title").section-header
              v-layout.section-description.font-weight-black
                  |{{selectedStep.content}}
</template>

<script>
  export default {

    props: {
      modal: Boolean,
    },
    data: () => ({
      selectedStep: {
        title: '',
        content: '',
      },
      steps: [
        {
          tabtitle: 'step 1',
          icon: require('@/assets/images/step1.png'),
          title: 'Search',
          content: 'Search for evidence-base content of accident, incident and events.',
          size: {
            width: '38.5pt',
            height: '52pt',
          },
        },
        {
          tabtitle: 'step 2',
          icon: require('@/assets/images/bulb.png'),
          title: 'Discover',
          content: 'Locate the footage you need.',
          size: {
            width: '48.5pt',
            height: '52pt',
          },
        },
        {
          tabtitle: 'step 3',
          icon: require('@/assets/images/cart.png'),
          title: 'Purchase',
          content: 'Purchase the content you selected.',
          size: {
            width: '38.5pt',
            height: '52pt',
          },
        },
      ],

    }),

    computed: {
      profile () {
        return this.$store.state.users.profile
      },
    },
    created () {
      this.setContent(0)
    },
    methods: {
      setContent (index) {
        this.selectedStep = {
          title: this.steps[index].title,
          content: this.steps[index].content,
        }
      },

    },
  }
</script>

<style lang="scss">
</style>
